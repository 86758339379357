import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"

import aboutStyles from "./about.module.css"

const AboutPage = ({ data }) => {
  const { html } = data.aboutPageData.edges[0].node
  return (
    <Layout>
      <SEO title="about" />
      <div
        className={aboutStyles.about}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </Layout>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query {
    aboutPageData: allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "about-page" } } }) {
      edges {
        node {
            html
        }
      }
    }
  }
`
